<template>
  <div>
    <b-card title="Dodanie do koszyka...">
      <b-card-text class="text-center">
        <img
            :src="introImg"
            alt="Chemmaster intro"
            style="width: 150px;"
        >
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'
import {useEcommerceUi} from "@/views/shop/useEcommerce";
import {useShopRemoteData} from "@/views/shop/useECommerceShop";
import {useEcommerce} from "@/views/apps/e-commerce/useEcommerce";
import store from "@/store";
import {useRouter} from "@core/utils/utils";
export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      introImg: require('@/assets/images/chemmaster/chemmaster-pani-sonia-witaj.png'),
    }
  },
  setup() {
    const { addProductInCart } =useEcommerce()
    const { getCartToken } = useShopRemoteData()
    const { router } = useRouter()
    let self = this;

    const initializeCart = () => {
      getCartToken()
    }
    const addToCart = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const productId = urlParams.get('product_id');

      addProductInCart(productId, null).then(() => {
        store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', store.state['app-ecommerce'].cartItemsCount + 1)
        router.push({name: 'checkout'})
      })

    }

    initializeCart();
    addToCart();
  }
}
</script>

<style>

</style>
